import AppProvider from "./src/codes/providers/appProvider";

export const onClientEntry = () => {
    window.onload = () => {
        // Define global variables
        window.globalDnsDeployment = false; // Unchanged
        window.claripHost = 'purdue.clarip.com'; // Unchanged
        window.claripCookieConsentHost = "purdue.clarip.com"; // Unchanged
        window.claripCdnHost = 'cdn.clarip.com'; // Unchanged
        window.clientName = 'purdue';  // CHANGED: Updated from 'purdue1' to 'purdue'
        window.claripClientName = "purdue"; // Unchanged, assuming this matches the new clientName requirement
        window.dnsControllerType = 'option-2'; // Unchanged
        window.doNotSellCookieName = 'clarip_dns_cookie';  // Unchanged
        window.doNotSellCookieValue = 1; // Unchanged
        window.doNotSellCookieExpirationAge = 1825; // Unchanged
        window.enableEnforcementScope = false; // Unchanged
        window.claripCookieManagerIdentifier = "6f5b27f84d2561415533";  // Unchanged, specific client request

        // Adding stylesheets for Do Not Sell and Cookie Consent
        // NOTE: If the stylesheet link needs to be updated to match 'purdue' instead of 'purdue1', adjust below
        const donotsellStylesheet = document.createElement('link');
        donotsellStylesheet.rel = "stylesheet";
        donotsellStylesheet.type = "text/css";
        // Assuming update is needed to match the 'purdue' client name:
        donotsellStylesheet.href = 'https://cdn.clarip.com/purdue/donotsell/assets/css/donotsell-extended.min.css'; // CHANGED: href updated
        document.head.appendChild(donotsellStylesheet);

        const cookieconsentStylesheet = document.createElement('link');
        cookieconsentStylesheet.rel = "stylesheet";
        cookieconsentStylesheet.type = "text/css";
        cookieconsentStylesheet.href = '//cdn.clarip.com/purdue/cookieconsent/assets/css/cookieconsent.min.css'; // Unchanged
        document.head.appendChild(cookieconsentStylesheet);

        // Adding scripts for Do Not Sell and Cookie Consent
        // Note: Update script sources if necessary to reflect 'purdue' client name changes
        const block3rdPartiesScript = document.createElement('script');
        // Assuming update is needed to match the 'purdue' client name:
        block3rdPartiesScript.src = 'https://cdn.clarip.com/purdue/donotsell/assets/js/block-3rd-parties.min.js'; // CHANGED: src updated
        document.body.appendChild(block3rdPartiesScript);

        const donotsellBlockScript = document.createElement('script');
        // Assuming update is needed to match the 'purdue' client name:
        donotsellBlockScript.src = 'https://cdn.clarip.com/purdue/donotsell/assets/js/donotsell-block.min.js'; // CHANGED: src updated
        document.body.appendChild(donotsellBlockScript);

        const cookieconsentScript = document.createElement('script');
        cookieconsentScript.src = '//cdn.clarip.com/purdue/cookieconsent/assets/js/cookieconsent.min.js'; // Unchanged
        document.head.appendChild(cookieconsentScript);

        const cookieManagerScript = document.createElement('script');
        cookieManagerScript.src = '//cdn.clarip.com/purdue/cookieconsent/assets/js/clarip-cookie-manager.min.js'; // Unchanged
        document.head.appendChild(cookieManagerScript);

        // Function to set the consent cookie
        function setConsentCookie() {
            console.log("Consent button clicked. Setting cookie...");

            const consentCookie = 'butrans_clarip_consent'; // Unchanged, specific to your implementation
            const currentDomain = window.location.hostname; // Dynamically get the current domain
            const cookieValue = 'true'; // User has given consent
            const expiryDays = 365; // Expiry in days

            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + expiryDate.toUTCString();

            document.cookie = consentCookie + "=" + cookieValue + ";" + expires + ";domain=" + currentDomain + ";path=/";
        }

        // Attach event listener to '.cc-allow' button
        document.addEventListener('DOMContentLoaded', () => {
          const consentButton = document.querySelector('.cc-allow');
          if (consentButton) {
            console.log("Attaching event listener to '.cc-allow' button");
            consentButton.addEventListener('click', setConsentCookie);
          } else {
            console.log("'.cc-allow' button not found");
          }
        });

        // Trigger DOMContentLoaded after a delay
        setTimeout(() => {
            window.dispatchEvent(new Event('DOMContentLoaded'));
        }, 1000);
    };
};

export const wrapRootElement = AppProvider;
