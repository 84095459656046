const metaData = {
    SITE_NAME: `Butrans® (buprenorphine) Transdermal System CIII`,
    SITE_DEFAULT_DESCRIPTION: `Butrans`,
    SITE_DEFAULT_KEYWORDS: ["butrans", "buprenorphine", "purdue"],
    PATIENT_JOB_CODE: `MR-08615 10/2024`,
    HCP_JOB_CODE: `MR-08615 10/2024`,
    CURRENT_YEAR: new Date().getFullYear(),
};

/**
 * HEADER_TYPE : fixed or regular
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */
const ISI_HEIGHT = 150;
const siteOptions = {
    INDICATIONS: ["hcp", "patient"],
    HEADER_TYPE: "fixed",
    STICKY_HEIGHT: ISI_HEIGHT,
    ISI_OPTIONS: {
        TYPE: "sticky",
        STICKY_STYLES: {
            backgroundColor: "#ffffff",
            height: ISI_HEIGHT,
            width: "100%",
            boxShadow: "0px 3px 10px 5px #d8d8d8",
            paddingTop: 5,
        },
    },
};
const hcpModalInfo = {
    BODY: `<p>Are you a Healthcare Professional or Patient/Caregiver?</p>`,
    BUTTON_ONE_CAPTION: `I am a healthcare professional`,
    BUTTON_TWO_CAPTION: `I am a patient or caregiver`,
    CLASS_NAME: `butrans-modal butrans-hcp-modal`,
};

const dailymedLinkInfo = {
    TITLE: `This link directs you to the National Library of Medicine's DailyMed website, which houses FDA-approved product labeling information.`,
    BODY: `Do you wish to leave Butrans.com?`,
    LINK_CLASS_NAME: `butrans-dailymed-link`,
    OVERLAY_CLASS_NAME: `butrans-modal butrans-dailymed-modal`,
};
const externalLinkInfo = {
    TITLE: `You are leaving Butrans.com and entering a site that is neither the property of nor controlled by Purdue Pharma L.P. or its subsidiaries.`,
    BODY: `Do you wish to leave Butrans.com?`,
    LINK_CLASS_NAME: `butrans-external-link`,
    OVERLAY_CLASS_NAME: `butrans-modal butrans-external-modal`,
};

const appConfigs = {
    metaData: metaData,
    siteOptions: siteOptions,
    hcpModalInfo: hcpModalInfo,
    dailymedLinkInfo: dailymedLinkInfo,
    externalLinkInfo: externalLinkInfo,
};

export default appConfigs;
